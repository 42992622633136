@tailwind base;
@tailwind components;
@tailwind utilities;
* {
  font-family: 'Quicksand', sans-serif;
}
.bgSignUp {
  background-image: url('./pages/images/agriculture.jpg');
}
.show {
  display: flex;
}
body{
  position: relative;
}
.hide {
  display: none;
}
.showgrid {
  display: grid;
}
.th-width {
  width: 40px !important;
}
#test {
  margin-bottom: 3px;
}
.spinner {
  font-size: 30px;
  animation: spin 0.7s linear infinite;
  color: #008080;
}
#mix {
  background-image: linear-gradient(rgba(0, 0, 0, 0.4), rgba(0, 0, 0, 0.4));

}

@keyframes spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
/* @import "http://fonts.googleapis.com/css?family=Montserrat:300,400,700"; */
.height{
  height: calc(100% - 55px);
}

h1 {
  font-weight: bold;
  width: 100%;
  text-align: center;
  letter-spacing: -1px;
  color: #000;
}
.rwd-table tr {
  height: max-content;
}
.rwd-table th {
  display: none;
}
.rwd-table td {
  display: block;
}
.rwd-table td:first-child {
  padding-top: .5em;
}
.rwd-table td:last-child {
  padding-bottom: .5em;
}
.rwd-table td:before {
  content: attr(data-th) ": ";
  font-weight: bold;
  /* width: 6.5em; */
  display: inline-block;
}
.rwd-table tr td{
  padding: 0px 5px;
}
@media (min-width: 768px) {
  .rwd-table td:before {
    display: none;
  }
}
.rwd-table tr{
  border-bottom: .5px solid rgba(128, 128, 128, 0.491);
  margin-top: 2px;
}
.rwd-table th, .rwd-table td { 
  border-right-width: 1px;
  border-radius: 2px;
  
}
.center{
  text-align: left !important;
}
.rwd-table td {
  text-align: left;
}
@media (min-width: 768px) {
  .rwd-table th, .rwd-table td {
    display: table-cell;
    align-items: center;
  }
  .rwd-table th:first-child, .rwd-table td:first-child {
    padding-left: 0;
  }
  .rwd-table th:last-child, .rwd-table td:last-child {
    padding-right: 0;
  }
}



.rwd-table {
  background: #f3f4f6;
  width: 100%;
  color: #000;
}
.rwd-table tr {
  border-top: 1px solid rgba(128, 128, 128, 0.491);
}

@media (max-width: 768px) {
  .rwd-table{
    display: grid;
    place-content: center;
  }
  .rwd-table.remove{
    display: block;
  }
  .rwd-table th, .rwd-table td {    
    padding: .4em !important;
  }
 
  .rwd-table tr td{  
    border: none;  
    width: 90vw;
  }
}

.rwd-table th, .rwd-table td:before {
  font-weight: 500;
  color: #008080;
}

/* Add some basic styling for the buttons */
.btn-edit, .btn-delete {
  margin: 0.5em;
  padding: 0.5em 1em;
  border: none;
  border-radius: 3px;
  font-size: 0.8em;
  cursor: pointer;
}
.btn-edit {
  background-color: #4CAF50; /* Green */
  color: white;
}
.btn-delete {
  background-color: #f44336; /* Red */
  color: white;
}
#center{
  text-align: center ;
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

input[type=number] {
  -moz-appearance: textfield;
}